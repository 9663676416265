import React,{useState,useEffect} from 'react'
import HotVacan from '../../components/hotVacancies/slider';
import ReactHtmlParser from "react-html-parser";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Erorr from '../Erorr/erorr';
import axios from 'axios';


export default function Id_vacansias(props) {

  const [isUpdate, setUpdate] =  useState(0);
  const [appState, setState] =  useState (null);
  const [linkName, setLinkName] = useState(null);
  const [isCountTest,setCountTest] = useState(null);
  const [isInformation, setInformation] = useState (null);

  const { newUrl } = useParams(); 

  useEffect(() => {
    axios.post('https://jobs2.belhard.com/job/api/control/page/filter/',{url__icontains:"/"+ newUrl})
    .then(res=> {
      const counts = res.data?.count;
        if (counts == 1) {
          const information = res.data?.results[0].section_page[0].info_section[0].text.split();
          setState(res?.data);
          setCountTest(counts);
          setInformation(information);
          setLinkName(res.data?.results[0].name);
        } 
    })
  }, [isUpdate]);

  return (
    <section> 
      <div className='cooperation_section'>
        <Breadcrumbs name={linkName !== null ? linkName : null} />
        <h1 className='cooperation_section_title'>{
        appState !== null ? appState.results[0].name:''}</h1>
          {
            isInformation !== null? isInformation.map((infor) => {
              return  (
                <> 
                  <p>{ReactHtmlParser(infor)}</p>
                </>
              )
          })
          : <Erorr/>
          }
      </div>
      <>
        <HotVacan  
          name={appState ? appState?.results[0].section_page[1].name : []} 
          vacancies={appState ? appState.results[0].section_page[1].slider_section[0].slider : []}
          back={appState ? appState.results[0].section_page[1].background : []}
        />
      </>
    </section>
  )

};