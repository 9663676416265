
import React,{Component}from "react";
import Buttons from '../../elements/buttons/button';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import CheckedElem from './currentVacanciesImg/chekedElem.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactHtmlParser from "react-html-parser";
import './currentVacanciesComponent.scss'

export default class currentSlider extends Component { 

  render() {
    const currentVac= {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay:200,
      arrows:false,
      responsive:[
        {
          breakpoint: 632,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots:true,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots:true,
          }
        },  
    ]
  };
        
  return( 
    <section className='currentVacanciesComponent'>
      <h1 className="currentVacanHeader">{this.props.text.name}</h1>
      <Slider {...currentVac}>
        {this.props.text.slider_section[0].slider.map(( i=> {
          return (
            <div>
              <Buttons 
                name={i.name}
                url={i.url}
              />
            </div>)
          }))
        }
      </Slider>
      <Link to={'/vacancies'} className="link--to__vacancies">
        {console.log(this.props.text.info_section[0].text, 'this.props.text.info_section[0].text')}
        {ReactHtmlParser(this.props.text.info_section[0].text)}
      </Link>
      <div className='GlobalInfo'>
        {this.props.text.catalogue_section.map(( i=> {
          return (
            <div>
              <p><img src={CheckedElem}/>{i.text}</p> 
            </div>)
          }))
        }
      </div>
    </section> );
  }
};