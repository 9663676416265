import React,{ useState, useEffect, useRef } from "react";
import { fallDown as Menu } from "react-burger-menu";
import { Link, useLocation } from "react-router-dom";
import Search from '../Navigations/NavigationsImg/search.png'; 
import WindowSearch from "../../elements/WindowSearch/WindowSearch";
import 'react-sticky-header/styles.css';
import axios from 'axios';
import './burgerMenu.scss';


export default BurgerMenu => {

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [appState, setState] =  useState (null);
  const [isUpdate, setUpdate] =  useState(0);
  const [valueFind, setValueFind] = useState('');
  const [navBackground, setNavBackground] = useState(null);
  const [appInformationHr, setInformationHr] = useState(null);


  const navRef = useRef();  
  const isLocationMobile = useLocation();
  const toggleMobileMenu = isLocationMobile.pathname === "/";
  const toggleButtonContacts = open ? "button_on_hrContacts" : "button_off_hrContacts" ;

  let sectionMobileStyle = toggleMobileMenu ? 'mobile-menu-style' : 'mobile-menu-style mobile-menu-style-dark'; 
  
  function test(item) {
    if(item === true){ 
      sectionMobileStyle = 'mobile-menu-style mobile-menu-style-dark'
    } 
  };

  test(navBackground);

  const closeMenu = () => {
    setOpenMenu(false)
  };

  const toggle = () => {
    setOpen(!open);
    if(openTwo) setOpenTwo(!openTwo);
    if(openMenu) setOpenMenu(!openMenu);
  };

  const toggleTwo = () => {
    setOpenTwo(!openTwo);
    if(open) setOpen(!open);
    if(openMenu) setOpenMenu(!openMenu);
  };

  const toggleMenu = () => {
    if(open) setOpen(!open);
    if(openTwo) setOpenTwo(!openTwo);
  };

  const handleStateChange = state => {
    setOpenMenu(state.isOpen);
    toggleMenu()  
  };

  const handleStateChangeMenu = state => {
    setOpen(state.isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 570
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    }
      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
    }
  }, [openMenu]);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/menu/1/')
    .then(res => {
      setState(res.data);
      setNavBackground(navRef.current)
    })
  }, [isUpdate, openMenu]);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/user/me/')
    .then(result => {
      const userSection = result.data.user_section;
      setInformationHr(userSection);
    })
  }, [isUpdate, openMenu]);

  useEffect(() => {
    axios.post("https://jobs2.belhard.com/job/api/control/page/filter/", {name: valueFind})
    .then(res => {
      let url = res.data.results[0].url;
      <Link to={`hr${url}`}/>
     
    })
    .catch(err => console.log(err))
  }, [valueFind, openMenu]);


  return (
    <>
      <section className={sectionMobileStyle}>    
        <Link to='/'> 
          <img className='belhard_logo' src={appState !== null ? appState?.logo : '32131'}/>   
        </Link>
        <div className ='Contacts'> 
          <div tabIndex={1} className="dd-header" role="button" >
            <div className={"button_off_hrContacts"} onClick={toggle}>
             <p>Контакты</p>
            </div>
            <Menu isOpen={open} right onStateChange={state => handleStateChangeMenu(state)}>
              {open && (
                <>
                  {appInformationHr !== "" ? appInformationHr.map((ContactHr) => { 
                    return ( 
                      <div className="hr-contacts-burgerHemu">
                        <div className="contactHrStyle">
                          <img className="hr_img_burger_menu" src={ContactHr.image}/>
                          <a href={ContactHr.text} target="_blank" >
                            {ContactHr.name}
                          </a>
                        </div> 
                      </div>
                    )})
                  : null} 
                </>
              )}
            </Menu>
          </div> 
        </div>
        <div className='search_section'> 
          <div tabIndex={2} className="dd-header" role="button">
            <div className="dd-header_search" onClick={toggleTwo}>
              <p>
                <img className='Search_menu-icon' src={Search}/>
              </p>
            </div>
              {openTwo && ( 
                <WindowSearch
                  text={'Поиск:'} 
                  setActive={setOpenTwo}
                />
              )}
          </div> 
        </div> 
          <Menu right isOpen={openMenu} onStateChange={state => handleStateChange(state)}>
            {appState !== null ? appState.menu.map((BurgerLink, index) => {
              return ( 
                <>
                  <Link key={index} to={BurgerLink.url} onClick={() => closeMenu()}>
                    {BurgerLink.name}
                  </Link>
                </>
              )})
            : null} 
          </Menu>
      </section>
    </>
  );

};