import React from 'react'
import PageLayout from './PageLayout/pageLayout'
import { BrowserRouter as Router, Route, Switch,  Redirect } from 'react-router-dom';
import CurrentVacancies from './pages/CurrentVacancies/currentVacancies';
import Home from './pages/HomePages/Home'
import Error from './pages/Erorr/erorr'
import potencialVacancies from './pages/PotentialVacancies/potencialVacancies';
import dinamicOfStaffingNeeds from './pages/dinamicOfStaffing/dinamicOfStaffiing'
import growingDepatments from './pages/growingdepartments/growingdepartments'
import coopetarionOptions from './pages/cooperationOptions/cooperationOptions'
import Faq from './pages/faq/faq';
import Id_vacansias from './pages/id_vacancies/id_vacancies';
import Grow from './pages/Grow/Grow';
import './App.css'
import './fonts/fonts.scss'
import './pages/responsive.scss'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <PageLayout>
        <Switch>
        <Route exact path ='/' component={Home}/>
        <Route exact path ='/vacancies' component={CurrentVacancies}/>
        <Route exact path ='/vacancies/:id'
          render={({match}) => {
            return <Faq />
          }}
        />
        <Route exact path ='/inactivevacancies' component={potencialVacancies}/>
        <Route exact path ='/inactivevacancies/:newUrl'
          render={({match}) => {
            return <Id_vacansias />
          }}
        />
        <Route exact path ='/news' component={dinamicOfStaffingNeeds}/>
        <Route exact path ='/specialcooperation' component={coopetarionOptions}/>
        <Route exact path ='/growingdepartments' component={growingDepatments}/>
        <Route exact path ='/:growId'
          render={({match}) => {
            return <Grow/> 
          }}
        />
        <Route exact path ='/growingdepartments/:growId'
          render={({match}) => {
            return <Grow/> 
          }}
        />
        <Redirect exact path="*" component={Error} />
        </Switch>
      </PageLayout>
    </Router>
  );
};

export default App;
