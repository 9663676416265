import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./downloadResume.scss";

const DownloadResume = (props) => {
  let history = useHistory();

  const { imgRus, urlRus, textone, imgUs, urlUs, texttwo } = props;

  const [colorText, setColorText] = useState(false);

  useEffect(() => {
    if (history.location.pathname === "/") {
      setColorText(true);
    } else {
      setColorText(false);
    }
  }, []);

  return (
    <div className="home--docoment__link">
      <div className="home--resyme__download">
        <img src={imgRus}></img>
        <a style={{ color: colorText ? "#fff" : "#000" }} href={urlRus}>
          {textone}
        </a>
      </div>
      <div className="home--resyme__download">
        <img src={imgUs}></img>
        <a style={{ color: colorText ? "#fff" : "#000" }} href={urlUs}>
          {texttwo}
        </a>
      </div>
    </div>
  );
};

export default DownloadResume;
