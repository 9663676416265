import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom';
import DownloadResume from '../../elements/downloadDock/downloadsResume';
import HotVacan from '../../components/hotVacancies/slider';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Hrstiky from '../../elements/hrstiky/hrstiky';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import'./potencialVacancies.scss';


export default function Protenc(){

  const [appState, setState] =  useState (null);
  const [vacanciesState, setVacanciesState] = useState(null);
  const [vacancieslinkState, setVacancieslinkState] = useState(null);
  const [isUpdate, setUpdate] =  useState(0);
  const [linkName, setLinkName] = useState(null);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/page/5/')
    .then(res=>{
      const arr = res.data.section_page[0].info_section[0].text.split('<br>');
      const arreus = res.data.section_page[1].info_section[0].text.split('<br>');

      setState(res.data);
      setVacanciesState(arr);
      setVacancieslinkState(arreus);
      setLinkName(res.data.name);
      }) 
  }, [isUpdate]);

 return (
     <section >
      <div className='potencialVacancies'>
        <Breadcrumbs name={linkName !== null ? linkName : null} />
        <h1 className="potencialVacancies-header">{appState !==null? appState.section_page[0].name:null}</h1>
          {vacanciesState !== null ? vacanciesState.map((vacancy) => {
            return <p className="potencialVacancies-subtitle">{ReactHtmlParser(vacancy)}</p>
            })
            : null
          }
        <DownloadResume 
          textone={appState !== null ? appState.section_page[0].image_section[0].name : null} 
          texttwo={appState !== null ? appState.section_page[0].image_section[1].name : null}  
          imgRus={appState !== null ? appState.section_page[0].image_section[0].image : null}  
          imgUs={appState !== null ? appState.section_page[0].image_section[1].image : null}
          urlRus={appState !== null ? appState.section_page[0].image_section[0].fileinfo : null}
          urlUs={appState !== null ? appState.section_page[0].image_section[1].fileinfo : null}
        />
        <div className="CurrentVacancies_line"></div>
        <h2 className ="potencialVacancies-header__two"> 
          Потенциальные вакансии для режима офисной занятости: 
        </h2>
        <div className="potencialVacancies-links">
          {vacancieslinkState !== null ? vacancieslinkState.map((vacancy) => {
            return <p> {ReactHtmlParser(vacancy)}</p>
            })
            : null
          }
        </div>
      </div>
    
      <>
        <HotVacan 
          name={appState ? appState.section_page[2].slider_section[0].name : []} 
          vacancies={appState ? appState.section_page[2].slider_section[0].slider : []}
          back={appState ? appState.section_page[2].background : []}  
        />
      </>
      <Hrstiky/>
    </section>
 )
};