import React from "react"
import Navigations from "./Navigations/Navigations"
import { useMediaQuery } from 'react-responsive'
import Footer from "./Footer/footer"
import BurgerMenu from './burgerMenu/burgerMenu'
import MobileFooter from './mobileFooter/mobileFooter'


export default function PageLayout ({children}){ 

  const isDesktopOrMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  })
  
 return (
   <React.Fragment>
       {isDesktopOrMobile !== true ?  <Navigations/> : <BurgerMenu/> }
       <div>{children}</div>
       {isDesktopOrMobile !== true ? <Footer/> : <MobileFooter/> } 
   </React.Fragment>
 )
}