import React, { useState, useEffect } from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import './Breadcrumbs.scss';


const Breadcrumbs = props => {

  const [link_name, setLink_name] = useState('');
  const [appStateMenu, setStateMenu] = useState(null);
  
  const { history, location, name } = props;
  const { pathname } = location;
  const pathnames = pathname.split("/").filter((el) => el);


  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/menu/1/')
      .then(res => { 
        setStateMenu(res.data.menu); 

        let FirstPathname = `/${pathnames[0]}`;
        let totalLink = res.data.menu.filter(item => item.url === FirstPathname);

        if(totalLink.length !== 0) {
          setLink_name(totalLink[0].name);
        }
      })
  },[]);

  
  return (
    <MUIBreadcrumbs separator="-" aria-label="breadcrumb">
      <Link
        style={{ cursor: "pointer" }}
        color="primary"
        onClick={() => history.push("/")}
        className={"link_bread"}
      >
        ГЛАВНАЯ
      </Link>
      {pathnames.map((pathname, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={index}>
            {name ? name.toUpperCase() : ""}
          </Typography>
        ) : (
          <Link key={index} onClick={() => history.push(routeTo)}>
            {link_name ? link_name.toUpperCase() : pathname}
           
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);