import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HotVacan from '../../components/hotVacancies/slider';
import Accordion from '../../components/accardion/accardion';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Hrstiky from '../../elements/hrstiky/hrstiky';
import ReactHtmlParser from "react-html-parser";
import Error from '../Erorr/erorr';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import'./Grow.scss';


export default function Grow(props) {
   
   const [isUpdate, setUpdate] = useState(0);
   const [appState, setState] = useState ('');
   const [linkName, setLinkName] = useState(null);
   const [appVacansies, setVacancies] = useState(null);
   
   const { growId } = useParams(); 

   useEffect(() => {
      axios.post('https://jobs2.belhard.com/job/api/control/page/filter/', {url__icontains:"/"+ growId})
      .then(res => {
         setState(res?.data);
         setLinkName(res.data?.results[0]?.name);
         setVacancies(res.data?.results[0]?.section_page[1]);
      }, err => console.log(err))
   }, [isUpdate, growId]);

   return (
      <section>
         <div className='cooperation_section'>
            {appState?.count == 0 ? 
            <Error/> 
            :
            <>
               <Breadcrumbs name={linkName !== null ? linkName : null} />
               <h1 className='cooperation_section_title'>
                  {appState !== '' ? appState?.results[0]?.name : ''}
               </h1> 

               {appState && appState !== '' ?
                  appState?.results[0]?.section_page[0]?.spoiler_section.map((i) => {
                    return(<Accordion props={i}/>)
                  })
               : null} 

               {appState && appState !== '' ?
                  appState?.results[0]?.section_page[0]?.info_section.map((i, index) => {
                    return (<div key={index}>{ReactHtmlParser(i.text)}</div>)
                  })
               : null}
            </>
            }

         </div>
         <>
            <HotVacan 
               name={appVacansies ? appVacansies?.name : []}
               vacancies={appVacansies  ? appVacansies?.slider_section[0]?.slider : []} 
               back={appVacansies  ? appVacansies?.background : []}
            /> 
         </>    
         <Hrstiky/>
      </section>
   )
};