import React, { useState, useEffect } from "react";
import Buttons from "../../elements/buttons/button";
import { Link } from "react-router-dom";
import HotVacan from "../../components/hotVacancies/slider";
import Offers from "../../components/Offers/offers";
import HrContact from "../../components/HrContacts/hr";
import CurrentVacanciesComponent from "../../components/currentVacanciesComponent/currentVacanciesComponent";
import FreshBeginnings from "../../components/fresh beginnings/freshbeginnings";
import Values from "../../components/Values/values";
import Messege from "./HomePagesImg/messege.png";
import DownloadResume from "../../elements/downloadDock/downloadsResume";
import Hrstiky from "../../elements/hrstiky/hrstiky";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import "./home.scss";

export default function Home() {
  const [appState, setState] = useState(null);
  const [isUpdate, setUpdate] = useState(0);
  const [background, setBackground] = useState("");

  const head = appState !== null ? appState.section_page[0].name : null;

  useEffect(() => {
    axios
      .get("https://jobs2.belhard.com/job/api/control/page/2/")
      .then((res) => {
        setState(res.data);
        console.log(res.data.section_page[0].background, "background");
        setBackground(res.data.section_page[0].background);
      });
  }, [isUpdate]);

  return (
    <section className="home-section">
      <div
        className="home-section__home"
        style={{ background: `url(${background})` }}
      >
        <h1 className="headers">{head}</h1>
        <p className="Subtitle">
          {appState !== null
            ? ReactHtmlParser(appState.section_page[0].info_section[0].text)
            : null}
        </p>
        <Buttons
          className="download"
          mailto="true"
          img={
            appState !== null
              ? appState.section_page[0].button_section[0].image
              : null
          }
          name={
            appState !== null
              ? appState.section_page[0].button_section[0].name
              : null
          }
          action={
            appState !== null
              ? appState.section_page[0].button_section[0].action
              : null
          }
        />
        <DownloadResume
          textone={
            appState !== null
              ? appState.section_page[0].image_section[0].name
              : null
          }
          texttwo={
            appState !== null
              ? appState.section_page[0].image_section[1].name
              : null
          }
          imgRus={
            appState !== null
              ? appState.section_page[0].image_section[0].image
              : null
          }
          imgUs={
            appState !== null
              ? appState.section_page[0].image_section[1].image
              : null
          }
          urlRus={
            appState !== null
              ? appState.section_page[0].image_section[0].fileinfo
              : null
          }
          urlUs={
            appState !== null
              ? appState.section_page[0].image_section[1].fileinfo
              : null
          }
        />
      </div>
      <div>
        <HotVacan
          name={
            appState ? appState.section_page[1].slider_section[0].name : null
          }
          vacancies={
            appState ? appState.section_page[1].slider_section[0].slider : []
          }
          back={appState ? appState.section_page[2].background : []}
        />
      </div>
      {appState !== null ? <Offers text={appState.section_page[2]} /> : null}
      {appState !== null ? (
        <CurrentVacanciesComponent text={appState.section_page[3]} />
      ) : null}
      <div>
        {appState !== null ? (
          <FreshBeginnings text={appState.section_page[4]} />
        ) : null}
      </div>
      {appState !== null ? <Values text={appState.section_page[5]} /> : null}
      <div>
        {appState !== null ? (
          <HrContact inform={appState.section_page[6]} />
        ) : null}
      </div>
      <Hrstiky />
    </section>
  );
}
