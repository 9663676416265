import React from 'react';
import ReactHtmlParser from "react-html-parser";
import './hrContacts.scss';

class HrContact extends React.Component { 
  render () { 
    return (
      <section className='hrContact'>
        <div className='hrContact_block'>
          {this.props.inform.info_section.map((i => {
            return <p className='hr_contacts_info'>{ReactHtmlParser(i.text)}</p>
          }))} 
        </div>
      </section>
      )
    }
 };

 export default HrContact;

