import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import cross from '../../assets/img/bytesize_close.svg';
import axios from 'axios';
import './WindowSearch.scss';

function WindowSearch({ text, active, setActive }) {

  const [value, setValue] = useState('');
  const [getData, setGetData] = useState([]);

  const locationPage = useLocation();

  const handleSubmit = e => {
    e.preventDefault();

    if(value !== '') {
      setValue(value);
    };
  };

  useEffect(() => {
    axios.post("https://jobs2.belhard.com/job/api/control/page/search/", {name: value})
    .then(res => {
      setGetData(res.data.results);
    })
    .catch(err => console.log(err))
  }, [value, locationPage.pathname])


  const options_find = () => {
    return(value ?
      <Scrollbars 
        className='main_list_find'
      >
        <ul className='list_find'  >
          {getData.length > 0 ? 
              getData?.map((item, index) => {
                return(
                  <li className='result_find' key={index}>
                    <Link to={item.url}>{item.name}</Link>
                  </li> 
              )}) : <li className='not_found'>Отсутствует</li>
            } 
        </ul> 
      </Scrollbars>
      : null
    )
  };


  return (
    <div className="modal_search_overlay" onClick={() => setActive(false)}> 
      <form onSubmit={handleSubmit}>
        <div className="block_search" onClick={e => e.stopPropagation()}>
        <div className="block_cross">
          <img src={cross} alt="cross" onClick={() => setActive(false)}/>
        </div>
          <span>{text.toUpperCase()}</span>
          <div className="block_search_item">
            <input type="text" value={value} onChange={e => setValue(e.target.value)}/>
          </div>
        </div>
      </form>
      {options_find()}
    </div>
  )
};

export default WindowSearch;