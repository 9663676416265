import React ,{ useState, useEffect }  from "react";
import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHtmlParser from 'react-html-parser'; 
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./offer.scss"; 


export default function Offers (props) {
      const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: 200,
            arrows: false,
            responsive:[
                  {
                        breakpoint: 1024,
                        settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true,
                        }
                  }
            ]
      };
      
      return(
            <>
                  <div className="Home--sotrudnicestvo">
                        <h3>{props.text.name}</h3> 
                        <h4>{ReactHtmlParser(props.text.info_section[0].text)}</h4>
                        <div className='solution-belhard'> 
                              <Slider {...settings}> 
                                    <div className="sotrud-section-one">
                                          {props.text.catalogue_section.slice(0,4).map(i=> {
                                                return(        
                                                      <div className="block_content">
                                                            <img src={i.image}/>
                                                            <p>{i.text}</p>
                                                      </div>       
                                          )})}
                                    </div>
                                    <div className='sotrud-section-two'>
                                          {props.text.catalogue_section.slice(4,8).map(i=> {
                                                return(
                                                      <div className="block_content">
                                                            <img src={i.image}/>
                                                            <p>{i.text}</p>
                                                      </div>
                                          )})}
                                    </div>  
                              </Slider>
                        </div>
                  </div> 
            </>
      )
};

