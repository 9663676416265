import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import DownloadResume from "../../elements/downloadDock/downloadsResume";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import HotVacan from "../../components/hotVacancies/slider";
import Hrstiky from "../../elements/hrstiky/hrstiky";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dinamicOfStaffing.scss";

export default function DynamicsOfStaffingNeeds() {
  const [isUpdate, setUpdate] = useState(0);
  const [appState, setState] = useState(null);
  const [isDinamic, setDinamic] = useState(null);
  const [linkName, setLinkName] = useState(null);

  useEffect(() => {
    axios
      .get("https://jobs2.belhard.com/job/api/control/page/3/")
      .then((res) => {
        setState(res.data);
        setLinkName(res.data.name);
        setDinamic(res.data.section_page[0].info_section);
      });
  }, [isUpdate]);

  console.log(appState, "appState");

  return (
    <section>
      <div className="dunamicOfStaffing">
        <Breadcrumbs name={linkName !== null ? linkName : null} />
        <h1 className="dunamics_header">
          {appState !== null ? appState?.section_page[0].name : null}
        </h1>
        <DownloadResume
          textone={
            appState !== null
              ? appState.section_page[0].image_section[0].name
              : null
          }
          texttwo={
            appState !== null
              ? appState.section_page[0].image_section[1].name
              : null
          }
          imgRus={
            appState !== null
              ? appState.section_page[0].image_section[0].image
              : null
          }
          imgUs={
            appState !== null
              ? appState.section_page[0].image_section[1].image
              : null
          }
          urlRus={
            appState !== null
              ? appState.section_page[0].image_section[0].fileinfo
              : null
          }
          urlUs={
            appState !== null
              ? appState.section_page[0].image_section[1].fileinfo
              : null
          }
        />
        <div className="CurrentVacancies_line" />
        <div className="dunamic_date-tag">
          {isDinamic !== null
            ? isDinamic.map((dinamicInfo) => {
                return (
                  <>
                    <strong>{dinamicInfo.name}</strong>
                    <a>{ReactHtmlParser(dinamicInfo.tags)}</a>
                    <p>{ReactHtmlParser(dinamicInfo.text)}</p>
                  </>
                );
              })
            : null}
        </div>
      </div>
      <>
        <HotVacan
          name={appState ? appState?.section_page[1].name : []}
          vacancies={
            appState ? appState?.section_page[1]?.slider_section[0].slider : []
          }
          back={appState ? appState?.section_page[1]?.background : []}
        />
      </>
      <Hrstiky />
    </section>
  );
}
