import React,{ useState, useEffect } from "react";
import { useLocation, Link } from 'react-router-dom';
import WindowSearch from '../../elements/WindowSearch/WindowSearch';
import BH_logo from "./NavigationsImg/BH_logo.png";
import Search from "./NavigationsImg/search.png";
import axios from "axios";
import './NavigationsStyle.scss';

export default function Navigations() {
   
   const [appState, setState] =  useState (null);
   const [isUpdate, setUpdate] =  useState(0);
   const [isNavigationLinkOne, setNavigationLinkOne] = useState(null);
   const [isNavigationLinkTwo, setNavigationLinkTwo] = useState(null);
   const [windowSearch, setWindowSearch] = useState(false);

   const toggle = () => setWindowSearch(!windowSearch);
   const locationPage = useLocation();
   const isHomes = locationPage.pathname === "/";

   useEffect(() => {
      axios.get('https://jobs2.belhard.com/job/api/control/menu/1/')
      .then(res => {
         const NavigationLink = res.data.menu.slice(0,6);
         const NavigationLinkSectionTwo = res.data.menu.slice(6,11);
         
         setState(res.data);
         setNavigationLinkOne(NavigationLink);
         setNavigationLinkTwo(NavigationLinkSectionTwo);
      })
   }, [isUpdate]);

   
   return (
      <header id='ds' className={isHomes ? "header-section--style" : "header-section--style_another_patch" }>
         <section className={isHomes ? "Nav-section--style" : "Nav-section--style-another-path"}> 
            <Link to='/'>
               <img className='imgBHlogo' src={appState ? appState.logo : BH_logo}></img>
            </Link>
            <div>
               <div>
                  <ul className="ndn">
                     {isNavigationLinkOne !== null ? isNavigationLinkOne.map((links) => {
                        return (
                           <> 
                              <li className="ndn_links">
                                 <Link className="link_header" to={links.url}>{links.name}</Link>
                              </li>
                           </> 
                           )
                        })
                        :null
                     }
                     <img className='imgSearch' src={Search} onClick={() => toggle(!windowSearch)}></img>    
                  </ul>
               </div>
               <div>
                  <ul className='ndn2'>
                     {isNavigationLinkTwo !== null ? isNavigationLinkTwo.map((linksTwo) =>{ 
                        return ( 
                           <> 
                              <li className="ndn_links">
                                 <Link className="link_header" to={linksTwo.url}>{linksTwo.name}</Link>
                              </li> 
                           </>
                           )
                        })
                        :null
                     }
                  </ul>
               </div>
            </div>
         </section>
         {windowSearch ? 
            <WindowSearch 
               text={'Поиск:'} 
               active={windowSearch}
               setActive={setWindowSearch}
            /> : null}
      </header>
   )
};