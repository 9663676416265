import React from 'react';
import { withRouter } from 'react-router-dom';
import Buttons from '../../elements/buttons/button';
import Slider from 'react-slick';
import './slider.scss';

 class HotVacan  extends React.Component {

  render() {

    const { location } = this.props;
    const isHome = location?.pathname === '/';
    const data = this.props?.vacancies;
    const background = this.props?.back;
    const count = data?.map(i => i);

    const HOTSLIDE = {
      dots: true,
      className: "main_slider",
      infinite: count?.length > 3,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: 200,
      arrows: false,
      responsive: [
        {
          breakpoint: 628,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };

    const styles = {

      fon: {
        background: `#fff`,
        height: "283px",
        padding: "0"
      },

      fon_back: {
        background: `linear-gradient(358.03deg, #2028619c 100%, rgba(10, 26, 145, 0) 28.43%), linear-gradient(180deg, #000424 0%, rgba(0, 0, 0, 0) 23.73%), linear-gradient(0deg, rgba(9, 46, 148, 0.6), rgba(0, 12, 45, 0.6)),url(${background})`,
        height: "283px",
        backgroundRepeat: "no-repeat",
        padding: "0"
      },

      title: {
        color: "#00072D",
        padding: "0px 0px 20px 0px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "28px",
        lineHeight: "36px",
        textAlign: "center",
        textTransform: "uppercase",
      },

      title_back: {
        color: "#fff",
        padding: "0px 0px 20px 0px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "28px",
        lineHeight: "36px",
        textAlign: "center",
        textTransform: "uppercase",
      }
      
    };


    const main_container = isHome ?  styles.fon : styles.fon_back;
    const title = isHome ?  styles.title : styles.title_back;
    const usStyle = isHome ? "sliderbar" : "sliderbar back";

    return ( 
     <section className={usStyle} style={main_container}> 
      <h1 className='hotheader' style={title}>{this.props?.name}</h1>
      <Slider {...HOTSLIDE}>
          {data?.map((vacancy) => {
              return(
                <div>
                  <Buttons 
                    className='hotBattons' 
                    name={vacancy?.name} 
                    url={vacancy?.url} 
                  />
                </div>
            )})
          }
        </Slider>
       </section> 
    );
  }
};

export default withRouter(HotVacan);
