import React from "react";
import './ClickStyle.scss';

class Buttons extends React.Component { 
  render () { 

    const action = this.props.action;
    const url = this.props.url;

    return(
        <div className="Button--style-z">
            {this.props.img ? <img src={this.props.img}/> : null}
            <span>
                {action ?
                    <a href={action ? action  : ""}> 
                        <span className="text_button_name">{this.props.name}</span> 
                    </a> 
                    :  
                    <a href={url ? url : ""}> 
                        <span className="text_button_name">{this.props.name}</span> 
                    </a> 
                }
            </span>
        </div>
    )
}};


export default Buttons;