import React,{ useState, useEffect } from 'react';
import DownloadResume from '../../elements/downloadDock/downloadsResume';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import HotVacan from '../../components/hotVacancies/slider';
import ReactHtmlParser from "react-html-parser";
import Hrstiky from '../../elements/hrstiky/hrstiky';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import'./growingdepartments.scss';
import axios from 'axios';


export default function Growing(){

  const [appState, setState] = useState (null);
  const [isUpdate, setUpdate] = useState(0);
  const [isSubtitle, setSubtitle] = useState(null);
  const [islinksLiv, setlinksLiv] = useState(null);
  const [isDopInormation, setDopInformation] = useState(null);
  const [linkName, setLinkName] = useState(null);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/page/6/')
    .then(res=>{
      const subtitlePars = res.data.section_page[0].info_section[0].text.split();
      const linksLiv = res.data.section_page[0].info_section[1].text.split();
      const dopInformatiom = res.data.section_page[1].info_section[0].text.split();
      setDopInformation(dopInformatiom);
      setlinksLiv(linksLiv);
      setSubtitle(subtitlePars);
      setLinkName(res.data.name);
      setState(res.data);        
  })
  }, [isUpdate]);


  return (
    <section >
      <div className='growingdepatments'>
        <Breadcrumbs name={linkName !== null ? linkName : null} />
        <h1 className="growingdepatments-header">{appState !== null ? appState.section_page[0].name:null}</h1>
        <DownloadResume 
          textone={appState !== null ? appState.section_page[0].image_section[0].name : null} 
          texttwo={appState !== null ? appState.section_page[0].image_section[1].name : null}  
          imgRus={appState !== null ? appState.section_page[0].image_section[0].image : null}  
          imgUs={appState !== null ? appState.section_page[0].image_section[1].image : null}
          urlRus={appState !== null ? appState.section_page[0].image_section[0].fileinfo : null}
          urlUs={appState !== null ? appState.section_page[0].image_section[1].fileinfo : null}
        /> 
        <div className="CurrentVacancies_line"></div>
        <div>
          {isSubtitle !== null ? isSubtitle.map((subtitles) => {
            return <p className="growing-subtitle-lunk"> {ReactHtmlParser(subtitles)}</p>})
            : null
          }
          {islinksLiv !== null ? islinksLiv.map((linksli) => {
            return <p> {ReactHtmlParser(linksli)}</p>})
            : null
          }
        </div>
        <div> 
          <h2 className="growingdepatments-header">
            {appState !== null ? appState.section_page[1].name:null}
          </h2>
            {isDopInormation !== null ? isDopInormation.map((dopInform) => {
              return <p>{ReactHtmlParser(dopInform)}</p>})
              : null
            } 
        </div>
      </div>
      <>
        <HotVacan 
          name={appState ? appState.section_page[2].slider_section[0].name : []} 
          vacancies={appState ? appState.section_page[2].slider_section[0].slider : []}
          back={appState ? appState.section_page[2].background : []}
        />
      </>
      <Hrstiky/>
    </section>
  )

};