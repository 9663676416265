import React, {useState,useEffect} from "react"
import Message from '../../elements/buttons/buttonsImg/Vector.png'
import Buttons from "../../elements/buttons/button"
import axios from "axios";
import './footer.scss';
import { Link } from "react-router-dom";

let today = new Date();
let year = today.getFullYear();

export default function Footer () {

  const [appState, setState] = useState (null);
  const [urlState, setUrlState] = useState (null);
  const [isUpdate, setUpdate] = useState(0);
  const [isFooterNavOne, setFooterNavOne] = useState(null);
  const [isFooterNavTwo, setFooterNavTwo] = useState(null);


  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/menu/2/')
    .then(res=>{
      const NavFooterSectionOne = res.data.menu.slice(0,6)
      const NavFooterSectionTwo = res.data.menu.slice(6,11)
      setState(res.data)
      setFooterNavOne(NavFooterSectionOne)
      setFooterNavTwo(NavFooterSectionTwo)
  })}, [isUpdate]);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/page/2/')
  .then(res=>{
    setUrlState(res.data)
  })}, [isUpdate]);


  return( 
    <footer className="footer_style">
      <div className="footer--style__list"> 
        <img className="footerImg" src={appState !== null ? appState.logo : ''}/> 
        <ul className="footer--list">
        {isFooterNavOne !== null ? isFooterNavOne.map((OneSectionNav) => {
            return <li><Link to={OneSectionNav.url}>{OneSectionNav.name}</Link></li> 
          })
        :null}
        </ul>
        <ul className="footer--list">
          {isFooterNavTwo !== null ? isFooterNavTwo.map((TwoSectionNav) => {
              return <li><Link to={TwoSectionNav.url}>{TwoSectionNav.name}</Link></li>
            })
          :null}
        </ul>
        <Buttons 
          className="button_footer_send" 
          img={Message} 
          name="Отправить резюме" 
          action={urlState !== null ? urlState.section_page[0].button_section[0].action : null}
        />
      </div>
      <div className='footer--style__info'>
        <span>Все права защищены | © Группа компаний «БелХард» 1994-{year}</span> 
        <div className='footer--link_company'>
          <span>
            <a href='https://www.belhard.com' target="_blank">www.belhard.com</a>
          </span>
          <span>
            <a href='mailto:job@belhard.com' target="_blank">info@belhard.com</a>
          </span>
        </div>
      </div>
    </footer>
  )

};