import React from "react";
import './mobileFooter.scss'
let today = new Date();
let year = today.getFullYear();


export default function mobileFooter() {
 return (
     <div className="mobileFooter">
   <span>Все права защищены  </span>
   <span>© Группа компаний «БелХард» {year}  </span>
   </div>
    )
 }
