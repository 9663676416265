import React, { useState, useEffect } from 'react'
import DownloadResume from '../../elements/downloadDock/downloadsResume';
import HotVacan from '../../components/hotVacancies/slider';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser'; 
import Hrstiky from '../../elements/hrstiky/hrstiky';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import'./Current.scss';


export default function CurrentVacancies(){

  const [isUpdate, setUpdate] =  useState(0);
  const [appState, setState] =  useState (null);
  const [linkName, setLinkName] = useState(null);
  const [vacanciesState, setVacanciesState] = useState(null);

  useEffect(() => {
    axios.get('https://jobs2.belhard.com/job/api/control/page/1/')
    .then(res=>{
      const arr = res.data.section_page[1].info_section[0].text.split('<br>')
        setState(res.data);
        setVacanciesState(arr);
        setLinkName(res.data.name);     
  })}, [isUpdate]);

 
 return (
    <section className='currentVacancies'>
      <div className='currentVacancies_div'>
        <Breadcrumbs name={linkName !== null ? linkName : null} />

        <h1 className="CurrentVacancies-header"> 
          {appState !== null ? appState.section_page[0].name : null}
        </h1>

        <p className="CurrentVacancies-subtitle">
          {appState !== null ? ReactHtmlParser(appState.section_page[0].info_section[0].text) : null}
        </p>

        <p className="CurrentVacancies-subtitle_two">
          {appState !== null ? ReactHtmlParser(appState.section_page[0].info_section[1].text) : null}
        </p>

        <DownloadResume 
          textone={appState !== null ? appState.section_page[0].image_section[0].name : null} 
          texttwo={appState !== null ? appState.section_page[0].image_section[1].name : null}  
          imgRus={appState !== null ? appState.section_page[0].image_section[0].image : null}  
          imgUs={appState !== null ? appState.section_page[0].image_section[1].image : null} 
          urlRus={appState !== null ? appState.section_page[0].image_section[0].fileinfo : null}
          urlUs={appState !== null ? appState.section_page[0].image_section[1].fileinfo : null}
        />

        <div className="CurrentVacancies_line"/>
        <h2 className ="CurrentVacancies-header__two"> 
          {appState !== null? appState.section_page[1].name:null} 
        </h2>
        <div className="CurrentVacancies-links" >
          {vacanciesState !== null ? vacanciesState.map((vacancy) => {
            return <p className='CurrentVacancies-link'>
                {ReactHtmlParser(vacancy)}
              </p>
            })
            : null
          }
        </div>
      </div>
      <>
        <HotVacan  
          name={appState ? appState.section_page[2].slider_section[0].name : []}  
          vacancies={appState ? appState.section_page[2].slider_section[0].slider : []}
          back={appState ? appState.section_page[2].background : []}
        />
      </>
      <Hrstiky/>
  </section>
 )

};