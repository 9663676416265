import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DownloadResume from "../../elements/downloadDock/downloadsResume";
import HotVacan from "../../components/hotVacancies/slider";
import ReactHtmlParser from "react-html-parser";
import Hrstiky from "../../elements/hrstiky/hrstiky";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./cooperationOptions.scss";

export default function CooperationsPage() {
  const [isUpdate, setUpdate] = useState(0);
  const [appState, setState] = useState(null);
  const [linkName, setLinkName] = useState(null);
  const [isCooperationInfo, setCoopetationInfo] = useState(null);

  useEffect(() => {
    axios
      .get("https://jobs2.belhard.com/job/api/control/page/7/")
      .then((res) => {
        const cooperationInfo =
          res?.data.section_page[1].info_section[0].text.split();
        setState(res?.data);
        setCoopetationInfo(cooperationInfo);
        setLinkName(res?.data.name);
      });
  }, [isUpdate]);

  return (
    <section>
      <div className="cooperation_section">
        <Breadcrumbs name={linkName !== null ? linkName : null} />
        <h1 className="cooperation-header">
          {appState !== null ? appState.section_page[0].name : null}
        </h1>
        <div className="coopetarion-downloadResume">
          <DownloadResume
            textone={
              appState !== null
                ? appState.section_page[0].image_section[0].text
                : null
            }
            imgRus={
              appState !== null
                ? appState.section_page[0].image_section[0].image
                : null
            }
            urlRus={
              appState !== null
                ? appState.section_page[0].image_section[0].fileinfo
                : null
            }
          />
        </div>
        <div className="CurrentVacancies_line" />
        <p className="coopetarion-subtitle">
          {appState !== null
            ? ReactHtmlParser(appState.section_page[0].info_section[0].text)
            : null}
        </p>
        <div>
          <h2 className="cooperation-header_two">
            {appState !== null ? appState.section_page[1].name : null}
          </h2>
          <div>
            {isCooperationInfo !== null
              ? isCooperationInfo.map((cooperation) => {
                  return <p>{ReactHtmlParser(cooperation)}</p>;
                })
              : null}
          </div>
        </div>
      </div>
      <>
        <HotVacan
          name={
            appState ? appState?.section_page[3]?.slider_section[0].name : []
          }
          vacancies={
            appState ? appState?.section_page[3]?.slider_section[0].slider : []
          }
          back={appState ? appState?.section_page[3]?.background : []}
        />
      </>
      <Hrstiky />
    </section>
  );
}
