import React, {Component} from "react";
import './values.scss';
import Slider from 'react-slick';
import Sergey from './ValuesImg/Ellipse 12 (1).png';
import Oleg from './ValuesImg/Ellipse 12 (3).png';
import Lev from './ValuesImg/Ellipse 13.png'; 


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay:200,
      arrows:false,
      responsive:[
        {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots:true,
          }
        },
      {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots:true,
        }
      }
    ]
  };

    return (
      <div className="users">
        <h2> {this.props.text.name}</h2>
        <Slider {...settings}>
        {this.props.text.slider_section[0].slider.map((i => {
            return (
            <>
              <div className="sergey">
                  <img src={i.logo}></img>
                  <p className="citats_style">
                  <span><b>{i.name}</b></span>
                  <span className="sergey_info">{i.text}</span>
                </p>
              </div>
            </>
            )
          }))}
        </Slider>
      </div>
    );
  }
};