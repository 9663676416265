import React from "react"
import './fleshBeginnings.scss'
import {Link} from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";

class freshBeginnings extends React.Component {

  render() {
    return (
      <section className='freshBeginnings'>
        <h1>{this.props.text.name} </h1>
        <div className="sectionFresh">
          {this.props.text.catalogue_section.map((i => {
              return (
                <div className="block_event_data">
                  <p className="date">{i.name}</p>
                  <p className="news">{i.text}</p>
                </div>
              )
            }))}
          </div>
          <Link to="/news">
            {ReactHtmlParser(this.props.text.info_section[0].text)}
          </Link>
      </section>
    )
  }
};
    
export default freshBeginnings;