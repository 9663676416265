import React, { useState, useEffect } from 'react';
import upshevron from './accordionImg/up.png';
import downShevron from './accordionImg/down.png';
import ReactHtmlParser from "react-html-parser";
import './accardion.scss';


function Accordion(props, {title,items = [], multiSelec = false}) {

  let id = props.props.id;
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if(selected === i) {
      return  setSelected(null)
    }
    setSelected(i)
  };

  useEffect(() => {
    setSelected(props.props.open_spoiler ? 1 : null);
  }, [props]);


  return ( 
    <> 
      {props.props &&
        <div tabIndex={0} className ="dd-header" role="button"> 
          <div className="dd-header_actions" onClick={() => toggle(id)}>
            <p>{props.props.name}</p>
            <div className="buttonss"> 
              <img src={downShevron} className={selected  ? "accordion_iconUp" : "accordion_iconDown"}/> 
            </div>
          </div>
            {selected  && ( 
              <ul className ='odd-listed' key={id} > 
                <li className ='dd-list-item'>
                  <p className="contactformAccordion">
                    {props.props.text}
                  </p>
                </li>
              </ul>)}
        </div>
      } 
    </>
  )
};

export default Accordion;