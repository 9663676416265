import React,{useState,useEffect} from 'react';
import Contacts from '../../assets/img/Call.svg';
import CloseContacts from '../../assets/img/bytesize_close.svg';
import axios from 'axios';
import './hrstiky.scss';


function Hrstiky({title, items = [], multiSelec = false}) {
    
    const[open, setOpen] = useState(false);
    const[appState, setState] = useState(null)
    const[isHrContacts, setHrContacts] = useState(null)
    const[isUpdate, setUpdate] = useState(0);

    const toggle = ()=> setOpen(!open);

    useEffect(() => {   
        axios.get('https://jobs2.belhard.com/job/api/user/me/')
        .then(res => {
            const HrContacts = res.data.user_section;
            setState(res.data);
            setHrContacts(HrContacts);
        })
     }, [isUpdate]);

    return ( 
        <div className ='dd-wrapper'> 
          <div tabIndex={0} className="dd-header" role="button">
            <div 
                className={open ? "dd-header_action_open" : "dd-header_action" } 
                onClick={()=>toggle(!open)}
            >
               <img src={open ? CloseContacts : Contacts}/>
               <p>{open ? 'Закрыть': 'Контакты'}</p>
            </div>
            {open && ( 
                <>
                    <ul className='odd-list'>
                        {isHrContacts !== '' ? isHrContacts.map((contacts, index)=> { 
                            return(
                                <li key={index}>
                                    <img src={contacts.image} />
                                    <a href={contacts.text} target="_blank">{contacts.name}</a>   
                                </li>
                        )})
                        :null
                        }
                    </ul>
                </>
            )}
          </div> 
        </div> 
    )
};


export default Hrstiky;
